import {
  ApolloClient, ApolloLink, HttpLink, InMemoryCache,
} from '@apollo/client';
import * as Sentry from '@sentry/browser';
import getConfig from 'next/config';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { useMemo } from 'react';

const { publicRuntimeConfig } = getConfig();

let apolloClient = null;
const isServerSide = typeof window === 'undefined';

function createApolloClient() {
  return new ApolloClient({
    ssrMode: isServerSide,
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      new RetryLink(),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.warn(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations, null, 2)}, Path: ${path}`,
            );
            const error = new Error('GraphQL error');
            error.payload = { message, locations, path };
            console.error(error);
            Sentry.captureException(error, error.payload);
          });
        }

        if (networkError) {
          console.warn(`[Network error]: ${networkError}`);
          const error = new Error('Network error');
          error.payload = networkError;
          Sentry.captureException(error, error.payload);
        }
      }),
      new HttpLink({
        uri: publicRuntimeConfig.GRAPHQL_ENDPOINT,
        credentials: 'same-origin',
        // useGETForQueries: true, // Currently throws error
      }),
    ]),
  });
}

export function initializeApollo(initialState = null) {
  const client = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    client.cache.restore(initialState);
  }

  // For SSG and SSR always create a new Apollo Client
  if (isServerSide) {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function useApollo(initialState) {
  return useMemo(() => initializeApollo(initialState), [initialState]);
}
